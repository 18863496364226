/*------------------------------>
	Site > Modules > Plugins
<------------------------------*/

	Site.Modules.Plugins = (function($, Site) {

		function init() {

			// Mobile Navigation
			Site.State.$body.find('.js-mobile-navigation').navigation({
				gravity: "right",
				maxWidth: "979px",
				type: "overlay"
			});

			// Subnavigation
			Site.State.$body.find('.js-subnavigation').navigation({
				theme: "no-theme",
				maxWidth: "979px"
			});

			// Backgrounds
			Site.State.$body.find(".js-background").background();

			// Equalize
			Site.State.$body.find('.js-equalize').equalize();

			// Lightbox
			Site.State.$body.find(".js-lightbox").lightbox();
			
			// Carousels
			Site.State.$body.find(".js-carousel").carousel({
				theme: "fs-light"
			});

			Site.State.$body.find(".js-product-image-carousel, .js-product-thumb-carousel").carousel({
				theme: "fs-light"
			});

			Site.State.$body.find(".js-product-thumb").on('itemClick.carousel', function(e) {
				var $this = $(this),
					index = $this.index();

				// Slide Carousel to Index
				Site.State.$body.find(".js-product-image-carousel").carousel('jump', index + 1);
			});

			// Dropdowns
			Site.State.$body.find(".js-dropdown").dropdown();

			// Checkboxes
			Site.State.$body.find(".js-checkbox").checkbox();

			// Number Spinners
			Site.State.$body.find(".js-number").number();

			// Swap
			Site.State.$body.find(".js-swap").swap().first().swap('activate');

			// Table Sorting
			Site.State.$body.find("table.js-table-sort").tablesorter();


			// Age Verification
			Site.State.$ageForm = Site.State.$body.find('#verify-age');

			if (Site.State.$ageForm.length > 0) {
				Site.State.$body.addClass('fs-navigation-lock');

				Site.State.$ageForm.submit(function(e) {
					e.preventDefault();
					e.stopPropagation();

					var $this = $(this);

					$this.parents('.age-verification').animate({opacity: 0}, 200, "linear", function() {
						$this.parents('.age-verification').remove();
					});
					Site.State.$body.removeClass('fs-navigation-lock');

					var data = {};
					data[csrfTokenName] = csrfTokenValue;

					$.post($this.attr('action'), data, {
						success: function(data) {
							
						}
					});
				});
			}

			Site.State.$body.find(".js-download").on('click', function(e) {
				e.preventDefault();

				downloadFile($(this).attr('href'));
			});
			
		}

		Site.InitFunctions.push(init);

	})(jQuery, Site);