/*------------------------------>
	Site > Modules > Video
<------------------------------*/

	Site.Modules.Video = (function($, Site) {

		function init() {

			/* Embedded Videos */

			var $embedded_videos = $('.rich-text iframe');

			$embedded_videos.each(function(e) {
				var $this = $(this),
					attr = $this.attr('src');


				console.log(attr);

				if ( attr.indexOf( 'youtu' ) !== -1 || attr.indexOf( 'vimeo' ) !== -1 ) {

					$this.wrap('<div class="video-wrapper"></div>');
				}
			});
		}

		Site.InitFunctions.push(init);

	})(jQuery, Site);
