/*------------------------------>
	Site > Modules > Navigation
<------------------------------*/

	Site.Modules.Navigation = (function($, Site) {

		function init() {
			
		}

		Site.InitFunctions.push(init);

	})(jQuery, Site);