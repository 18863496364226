/*------------------------------>
	Site > Modules > Navigation
<------------------------------*/

	Site.Modules.BeerFinder = (function($, Site) {

		var $searchResults,
			$searchResultsButton,
			$productInput;

		function swapTargets(e) {
			$this = $(this);

			$searchResults.toggleClass('open');
		}

		function substringMatcher(strs) {
			return function findMatches(q, cb) {
				var matches, substringRegex;

				// an array that will be populated with substring matches
				matches = [];

				// regex used to determine if a string contains the substring `q`
				substrRegex = new RegExp(q, 'i');

				// iterate through the pool of strings and for any string that
				// contains the substring `q`, add it to the `matches` array
				$.each(strs, function(i, str) {
					if (substrRegex.test(str)) {
						matches.push(str);
					}
				});

				cb(matches);
			};
		}

		function init() {
			$searchResults = Site.State.$body.find('.js-beer-finder-results');
			$searchResultsButton = $searchResults.find('.js-beer-finder-results-button');

			$productInput = Site.State.$body.find('#BeerFinderProduct');
			if (window.autocomplete !== undefined) {
				$productInput.typeahead({
					hint: true,
					highlight: true,
					minLength: 1
				},
				{
					name: 'beer',
					source: substringMatcher(autocomplete)
				});
			}

			$searchResultsButton.on('click', swapTargets);
		}

		Site.InitFunctions.push(init);

	})(jQuery, Site);
