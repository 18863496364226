/*------------------------------>
	Site > Modules > Screen
<------------------------------*/

	Site.Modules.Screen = (function($, Site) {
		this.minXS = "320";
		this.minSM = "500";
		this.minMD = "740";
		this.minLG = "980";
		this.minXL = "1220";

		this.maxXS = this.minXS - 1;
		this.maxSM = this.minSM - 1;
		this.maxMD = this.minMD - 1;
		this.maxLG = this.minLG - 1;
		this.maxXL = this.minXL - 1;

		this.minHTsm = "400";
		this.minHT   = "800";

		this.maxHTsm = this.minHTsm - 1;
		this.maxHT   = this.minHT - 1;

		this.touch = false;

		function onResize() {
			Site.windowWidth  = Site.State.$window.width();
			Site.windowHeight = Site.State.$window.height();

			Site.iterate(Site.ResizeFunctions);
		}

		function onRespond(e, data) {
			Site.windowData = data;

			Site.iterate(Site.RespondFunctions);
		}

		function onScroll() {
			Site.iterate(Site.ScrollFunctions);
		}

		function init() {
			this.touch = $("html").hasClass("touch");

			onResize(null);

			Site.State.$window.on("mqchange.mediaquery", onRespond)
							 .on(Site.ns("resize"), onResize)
							 .on(Site.ns("scroll"), onScroll);
		}

		Site.InitFunctions.push(init);

	})(jQuery, Site);